<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">
                <div class="card bgCustom">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <div class="col-lg-3">
                                <v-select v-model="millModel" :items="millLists" default="" item-value="mill_id" item-text="mill_name" label="Select Mill" clearable dense prepend-icon="mdi-factory"></v-select>
                            </div>
                            <div class="col-lg-3">
                                <v-select v-model="entityModel" :items="entityLists" default="" item-value="entity" item-text="entity" label="Select Entity" clearable dense prepend-icon="mdi-factory"></v-select>
                            </div>
                            <div class="col-lg-3">
                                <v-autocomplete v-model="orderIdModel" :items="orderIdLists" default="" item-value="CustomerOrderNo" :item-text="item => item.CustomerOrderNo +' - '+ item.NamaCustomer" label="Enter Order ID" clearable dense prepend-icon="mdi-cart" @keyup.enter="updateOrderIdDropdown()" @change="(event) => updateOrderIdDropdown(event)" @mouseenter="(event) => emptyOrderID(event)" id="order_id">
                                    <template v-slot:no-data><v-list-item>
                                        <v-list-item-title>Put <strong>Order ID</strong>, then press enter</v-list-item-title></v-list-item>
                                    </template>
                                </v-autocomplete>
                            </div>
                            <div class="col-lg-3">
                                <v-select v-model="itemNumModel" :items="itemNumLists" default="" item-value="item_num" :item-text="item => item.item_num +'. '+ item.NamaBarang" label="Select Item No." clearable dense prepend-icon="mdi-factory"></v-select>
                            </div>
                            <div class="col-lg-4">
                                <v-dialog ref="dialog" v-model="modalDateFrom" :return-value.sync="dateFrom" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateFrom" label="Start Period" dense readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateFrom" type="month" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalDateFrom = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog.save(dateFrom)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-4">
                                <v-dialog ref="dialog2" v-model="modalDateTo" :return-value.sync="dateTo" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateTo" label="End Period" dense readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateTo" type="month" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalDateTo = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog2.save(dateTo)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-4 text-right">
                                <v-btn class="mr-4" color="primary" elevation="2" large rounded @click="getChartData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 cardGraph1">
                <div class="card bgCustom2">
                    <div class="card-body">
                        <div id="chartContainer1" style="height: 350px; width: 100%;"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 cardGraph2">
                <div class="card bgCustom2">
                    <div class="card-body">
                        <div id="chartContainer2" style="height: 350px; width: 100%;"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 cardGraph3">
                <div class="card bgCustom2">
                    <div class="card-body">
                        <div class="col-lg-12">
                            <h5 class="card-title text-primary">With Order Note</h5>
                            <v-data-table :headers="headers" :items="itemLists" class="elevation-1" page-count="5" :search="searchItem" :loading="loadingDatatable" dense>
                                <template v-slot:top>
                                    <v-toolbar flat color="white">
                                        <div class="d-flex w-25">
                                            <v-text-field v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                        </div>
                                    </v-toolbar>
                                </template>
                            </v-data-table>
                        </div>
                        <div class="col-lg-12">
                            <h5 class="card-title text-primary">Without Order Note</h5>
                            <v-data-table :headers="headers2" :items="itemLists2" class="elevation-1" page-count="5" :search="searchItem2" :loading="loadingDatatable2" dense>
                                <template v-slot:top>
                                    <v-toolbar flat color="white">
                                        <div class="d-flex w-25">
                                            <v-text-field v-model="searchItem2" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                        </div>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.x`]="{ item }">
                                    <div v-if="item.x == '-3000'">
                                        N/A
                                    </div>
                                    <div v-else>
                                        {{ item.x }}
                                    </div>
                                </template>
                                <template v-slot:[`item.y`]="{ item }">
                                    <div v-if="item.y == '-3000'">
                                        N/A
                                    </div>
                                    <div v-else>
                                        {{ item.y }}
                                    </div>
                                </template>
                                <template v-slot:[`item.z`]="{ item }">
                                    <div v-if="item.z == '-3000'">
                                        N/A
                                    </div>
                                    <div v-else>
                                        {{ item.z }}
                                    </div>
                                </template>
                            </v-data-table>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'GBRK',
                disabled: false,
                href: '/admin/gbrk',
                },
                {
                text: 'GBRK Analysis',
                disabled: false,
                href: '#',
                },
                {
                text: 'Order to Ship Performance',
                disabled: true,
                href: '#',
                },
            ],
            millModel: '',
            millLists: [],
            entityModel: '',
            entityLists: [],
            orderIdModel: '',
            orderIdLists: [],
            itemNumModel: '',
            itemNumLists: [],
            dateFrom: '',
            modalDateFrom: false,
            dateTo: '',
            modalDateTo: false,
            dateFromFormatted: '',
            dateToFormatted: '',
            itemLists: [],
            headers: [
                { text: 'MILL', value: 'mill_id', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'RECEIVE DATE', value: 'dt_rcv', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PLAN DATE', value: 'dtPlan', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PROD. DATE', value: 'dtProd', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'APPROVE 2', value: 'dtShip', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'ORDER NO.', value: 'CustomerOrderNo', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'CUSTOMER', value: 'NamaCustomer', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'ENTITY', value: 'custGroup', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'ITEM NO.', value: 'item_num', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DESCR.', value: 'NamaBarang', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'RECV. TO PLAN', value: 'x', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PLAN TO PROD', value: 'y', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PROD TO SHIP', value: 'z', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'ORDER NOTE', value: 'order_note', align: 'left', class: 'primary--text blue lighten-5' },
            ],
            searchItem: '',
            loadingDatatable: false,
            itemLists2: [],
            headers2: [
                { text: 'MILL', value: 'mill_id', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'RECEIVE DATE', value: 'dt_rcv', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PLAN DATE', value: 'dtPlan', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PROD. DATE', value: 'dtProd', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'APPROVE 2', value: 'dtShip', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'ORDER NO.', value: 'CustomerOrderNo', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'CUSTOMER', value: 'NamaCustomer', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'ENTITY', value: 'custGroup', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'ITEM NO.', value: 'item_num', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DESCR.', value: 'NamaBarang', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'RECV. TO PLAN', value: 'x', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PLAN TO PROD', value: 'y', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PROD TO SHIP', value: 'z', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'ORDER NOTE', value: 'order_note', align: 'left', class: 'primary--text blue lighten-5' },
            ],
            searchItem2: '',
            loadingDatatable2: false,
        }
    },
    async mounted(){

        this.initialize()

    },
    methods:{
        
        async initialize(){

            this.$store.dispatch('setOverlay', true)

            document.querySelector(".cardGraph1").style.display = "none";
            document.querySelector(".cardGraph2").style.display = "none";
            document.querySelector(".cardGraph3").style.display = "none";

            this.dateFrom = this.currentPeriod()
            this.dateTo = this.currentPeriod()

            await axios.get(`${process.env.VUE_APP_URL}/api/gbrk/OrderToShipPerformance`, { 
                headers: { 
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                this.$store.dispatch('setOverlay', false)
                this.millLists = res.data.mill
                this.entityLists = res.data.entity
            })
        },

        async getChartData(){

            if(this.millModel == null || this.millModel == ''){
                Swal.fire({
                        text: 'Please Select Mill',
                        icon: 'error',
                    })
                return false
            }

            document.querySelector(".cardGraph1").style.display = "none";
            document.querySelector(".cardGraph2").style.display = "none";
            document.querySelector(".cardGraph3").style.display = "none";
            this.$store.dispatch('setOverlay', true)

            if (this.dateFrom) {
                this.dateFromFormatted = new Date(this.dateFrom).toISOString().substr(0, 7).replace(/-/g,"")
            } else  {
                this.dateFromFormatted = ''
            }

            if (this.dateTo) {
                this.dateToFormatted = new Date(this.dateTo).toISOString().substr(0, 7).replace(/-/g,"")
            } else  {
                this.dateToFormatted = ''
            }

            await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/OrderToShipPerformance/getChartData`, { 

                    mill: this.millModel ? this.millModel : '',
                    entity: this.entityModel ? this.entityModel : '',
                    orderId: this.orderIdModel ? this.orderIdModel : '',
                    itemNum: this.itemNum ? this.itemNum : '',
                    dateFrom: this.dateFromFormatted,
                    dateTo: this.dateToFormatted,

                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {
                if(res.data.arr_list1.length != 0){
                    this.$store.dispatch('setOverlay', false)
                    document.querySelector(".cardGraph1").style.display = "block";
                    document.querySelector(".cardGraph2").style.display = "none";
                    document.querySelector(".cardGraph3").style.display = "none";
                    this.renderChart1(res.data.arr_list1, res.data.arr_list2, res.data.arr_list3, res.data.arr_list4)
                } else {
                    document.querySelector(".cardGraph1").style.display = "none";
                    document.querySelector(".cardGraph2").style.display = "none";
                    document.querySelector(".cardGraph3").style.display = "none";
                    this.$store.dispatch('setOverlay', false)
                    Swal.fire({
                        text: 'Data not found !',
                        icon: 'error',
                    })
                }
            })

        },

        async getChartDataDetails(e){

            this.$store.dispatch('setOverlay', true)
            document.querySelector(".cardGraph2").style.display = "none";
            document.querySelector(".cardGraph3").style.display = "none";

            await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/OrderToShipPerformance/getChartDataDetails`, { 

                mill: this.millModel ? this.millModel : '',
                entity: this.entityModel ? this.entityModel : '',
                orderId: this.orderIdModel ? this.orderIdModel : '',
                itemNum: this.itemNum ? this.itemNum : '',
                period: e.dataPoint.label

            },
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                }
            })
            .then(res => {
                
                this.$store.dispatch('setOverlay', false)
                document.querySelector(".cardGraph2").style.display = "block";
                this.renderChart2(res.data.arr_list1, res.data.arr_list2, res.data.arr_list3, res.data.arr_list4)
                console.log(res.data)

            })

        },

        async getDataDetails(e){

            this.$store.dispatch('setOverlay', true)
            document.querySelector(".cardGraph3").style.display = "none";

            this.itemLists = []
            this.itemLists2 = []

            await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/OrderToShipPerformance/getDataDetails`, { 

                mill: this.millModel ? this.millModel : '',
                entity: this.entityModel ? this.entityModel : '',
                orderId: this.orderIdModel ? this.orderIdModel : '',
                itemNum: this.itemNum ? this.itemNum : '',
                date: e.dataPoint.label

            },
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                }
            })
            .then(res => {
                
                this.$store.dispatch('setOverlay', false)
                document.querySelector(".cardGraph3").style.display = "block";
                this.itemLists = res.data.result1
                this.itemLists2 = res.data.result2
                console.log(res.data)

            })

        },

        renderChart1(data1, data2, data3, data4){

            var chart1 = new CanvasJS.Chart("chartContainer1", {
                animationEnabled: true,
                theme: "light2",
                title: {
                    text: "Chart Performance Analysis (Monthly Summary)",
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: false,
                axisY: {
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: "Day Interval"
                },
                axisY2: {
                    title: "Count Order Note",
                    titleFontColor: "#0f4c75",
                    lineColor: "#0f4c75",
                    labelFontColor: "#0f4c75",
                    tickColor: "#0f4c75"
                },
                toolTip:{
                    shared:true
                },
                legend: {
                    fontSize: 11,
                    itemMaxWidth: 150,
                    itemWrap: true ,
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                data: [
                    {
                        type: "column",
                        click: this.getChartDataDetails,
                        showInLegend: true,
                        name: "RCV -> PLAN",
                        indexLabel: "{y}",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                        dataPoints: data1
                    },
                    {
                        type: "column",
                        click: this.getChartDataDetails,
                        showInLegend: true,
                        name: "PLAN->PROD",
                        indexLabel: "{y}",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                        dataPoints: data2
                    },
                    {
                        type: "column",
                        click: this.getChartDataDetails,
                        showInLegend: true,
                        name: "PROD->SHIP",
                        indexLabel: "{y}",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                        dataPoints: data3
                    },
                    {
                        type: "spline",
                        click: this.getChartDataDetails,
                        name: "Count OrderNote",
                        axisYType: "secondary",
                        showInLegend: true,
                        indexLabel: "{y}",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                        dataPoints: data4
                    },
                ]
            })

            chart1.render()

        },

        renderChart2(data1, data2, data3, data4){

            var chart2 = new CanvasJS.Chart("chartContainer2", {
                animationEnabled: true,
                theme: "light2",
                title: {
                    text: "Chart Performance Analysis (Daily Summary)",
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: false,
                axisY: {
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: "Day Interval"
                },
                axisY2: {
                    title: "Count Order Note",
                    titleFontColor: "#0f4c75",
                    lineColor: "#0f4c75",
                    labelFontColor: "#0f4c75",
                    tickColor: "#0f4c75",
                },
                toolTip:{
                    shared:true
                },
                legend: {
                    fontSize: 11,
                    itemMaxWidth: 150,
                    itemWrap: true ,
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                data: [
                    {
                        type: "column",
                        click: this.getDataDetails,
                        showInLegend: true,
                        name: "RCV -> PLAN",
                        indexLabel: "{y}",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                        dataPoints: data1
                    },
                    {
                        type: "column",
                        click: this.getDataDetails,
                        showInLegend: true,
                        name: "PLAN->PROD",
                        indexLabel: "{y}",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                        dataPoints: data2
                    },
                    {
                        type: "column",
                        click: this.getDataDetails,
                        showInLegend: true,
                        name: "PROD->SHIP",
                        indexLabel: "{y}",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                        dataPoints: data3
                    },
                    {
                        type: "spline",
                        click: this.getDataDetails,
                        name: "Count OrderNote",
                        axisYType: "secondary",
                        showInLegend: true,
                        indexLabel: "{y}",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                        dataPoints: data4
                    }
                ]
            })

            chart2.render()

        },

        updateOrderIdDropdown()
        {
            var id = $('#order_id').val()
            if(id){
                this.itemNumModel = ''
                this.itemNumLists = []
                axios.get(`${process.env.VUE_APP_URL}/api/gbrk/OrderToShipPerformance/getOrderID?orderid=${id}`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                    })
                    .then(res => {
                        if(res.data.result.length != 0){
                            this.orderIdLists = res.data.result
                            this.itemNumLists = res.data.result2
                        } else {
                            Swal.fire({
                                text: 'Order ID not found, please try again !',
                                icon: 'error',
                            })
                        }
                })
            } else {
                this.orderIdModel = ''
                this.orderIdLists = []
            }

        },

        emptyOrderID(id)
        {
            if(id == null){
                this.orderIdModel = ''
                this.orderIdLists = []
            }
        },

        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },

        currentPeriod() {
            const current = new Date();
            current.setDate(current.getDate() + 20);

            const date = current.getFullYear() + '-' + ('0' + (current.getMonth())).slice(-2);      
            return date;
        }

    }
    
}
</script>

<style scoped>

    .v-application p {
        margin-bottom: 0px !important;
    }

    .v-text-field {
        padding-top: 0px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1rem;
    }

    .bgCustom {
        background: linear-gradient(to right, #fff, #f1f1f1) !important;
        color: #fff;
    }

    .bgCustom2 {
        background: #bde0fe !important;
        color: #fff;
    }

    .bgCustom3 {
        background: rgba(19, 64, 116, 0.5);
        color: #fff;
    }

    .container{
        margin-bottom: 60px !important;
    }

</style>